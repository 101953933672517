import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import { ContactSupport } from '@truphone/support-components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import LocaleProvider, { LocaleContext } from '../../contexts/LocaleProvider'

function encode(string) {
  return encodeURIComponent(string).replaceAll("'", '%27')
}
function createCompositeName(name, label, emailLabel) {
  return `${name}:${label ? encode(label) : ''}:${emailLabel ? encode(emailLabel) : ''}`
}

function FormWrapper({ datoCmsContactSupport, mapField, locale }) {
  const localeContext = useContext(LocaleContext)

  return (
    <ContactSupport
      hero={datoCmsContactSupport.hero}
      supportEmails={datoCmsContactSupport.supportEmails}
      form={{
        tabs: datoCmsContactSupport.tabs.map((x) => ({
          title: x.title,
          field: mapField(x.categoryField.id),
          thankYouText: x.thankYouMessageNode?.childMarkdownRemark.html
        })),
        locale,
        userLocation: localeContext.iso
      }}
      loadingText={datoCmsContactSupport.loadingText}
    />
  )
}

function ContactSupportTemplate({
  data: {
    datoCmsSite,
    datoCmsContactSupport,
    allDatoCmsContactSupportField,
    allDatoCmsSupportIndex
  },
  pageContext: { menu, footer, cookiePrompt, consumer, locale }
}) {
  const mapFields = (fields) => {
    return fields
      .map((field) => {
        let {
          type,
          fieldName,
          label,
          emailLabel,
          placeholder,
          options,
          required,
          requiredMessage,
          pattern,
          patternMessage,
          maxLength
        } = field

        fieldName = createCompositeName(fieldName, label, emailLabel)
        if (options && options.length > 0) {
          options = options.map((x) => (!x.value ? { ...x, value: x.text } : x))
        }

        switch (type) {
          case 'DatoCmsContactSupportFormSelect':
            return {
              type: 'select',
              fieldName,
              label,
              placeholder,
              required,
              requiredMessage,
              options
            }
          case 'DatoCmsContactSupportFormOptionGroup':
            return {
              type: 'options',
              fieldName,
              label,
              required,
              requiredMessage,
              options
            }
          case 'DatoCmsContactSupportFormDecision':
            return {
              type: 'options',
              fieldName,
              label,
              required,
              requiredMessage,
              options,
              style: 'inline'
            }
          case 'DatoCmsContactSupportFormFeaturedArticle':
            return {
              type: 'featured',
              fieldName,
              label,
              required,
              options,
              collapsed: field.initiallyCollapsed,
              articles: field.article && [
                {
                  title: field.article.title,
                  subtitle: field.article.subtitle,
                  content: field.article.content,
                  href: `${
                    field.article.supportProducts &&
                    field.article.supportProducts.length > 0 &&
                    allDatoCmsSupportIndex.edges.find((y) =>
                      y.node.supportProducts.find(
                        (z) => z.id === field.article.supportProducts[0]?.id
                      )
                    )?.node.url
                  }${field.article.slug}/`
                }
              ]
            }
          case 'DatoCmsContactSupportFormTextContent':
            return {
              type: 'end',
              content: field.contentNode.childMarkdownRemark.html
            }
          case 'DatoCmsContactSupportFormTextField':
            return {
              type: 'text',
              fieldName,
              label,
              required,
              requiredMessage,
              pattern,
              patternMessage,
              maxLength
            }
          case 'DatoCmsContactSupportFormTextArea':
            return {
              type: 'text-area',
              fieldName,
              label,
              required,
              requiredMessage,
              maxLength
            }
          case 'DatoCmsContactSupportFormDateField':
            return {
              type: 'date',
              fieldName,
              label,
              required,
              requiredMessage
            }
          case 'DatoCmsContactSupportFormTimeField':
            return {
              type: 'time',
              fieldName,
              label,
              required,
              requiredMessage
            }
          case 'DatoCmsContactSupportFormEidField':
            return {
              type: 'eid',
              fieldName,
              label,
              placeholder,
              required,
              requiredMessage,
              pattern:
                /^(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{5})(\s{0,1})(\d{2})$/,
              patternMessage
            }
          case 'DatoCmsContactSupportFormCountrySelect':
            if (field.multiselect) {
              return {
                type: 'multi-select',
                fieldName,
                label,
                language: field.language,
                required,
                requiredMessage,
                multiple: field.multiselect
              }
            } else {
              return {
                type: 'country-select',
                fieldName,
                label,
                language: field.language,
                required,
                requiredMessage,
                populateIp: field.populateIpLocation
              }
            }
          case 'DatoCmsContactSupportFormSlider':
            return {
              type: 'slider',
              fieldName,
              label,
              required,
              options
            }
          case 'DatoCmsContactSupportFormSubmitButton':
            return {
              type: 'button',
              buttonText: field.buttonText,
              thankYouMessage: field.thankYouMessageNode?.childMarkdownRemark.html,
              enableRecaptcha: field.enableRecaptcha,
              recaptchaRequiredMessage: field.recaptchaRequiredMessage
            }

          case 'DatoCmsContactSupportFormOutsideLink':
            return {
              type: 'featured',
              collapsed: true,
              articles: [
                {
                  title: field.title,
                  subtitle: field.subtitle,
                  href: field.url
                }
              ]
            }
          case 'DatoCmsCaseManagementProvisionCase':
            return {
              type: 'case-management-provision',
              caseProduct: field.caseProduct,
              caseType: field.caseType,
              caseCategory: field.caseCategory,
              caseReason: field.caseReason,
              caseServiceLoss: field.caseServiceLoss
            }
          case 'DatoCmsCaseManagementGeneralInquiryCase':
            return {
              type: 'case-management-general-inquiry',
              caseProduct: field.caseProduct,
              caseType: field.caseType,
              caseCategory: field.caseCategory
            }
          case 'DatoCmsCaseManagementCustomerFaultCase':
            return {
              type: 'case-management-customer-fault',
              caseProduct: field.caseProduct,
              caseType: field.caseType,
              caseCategory: field.caseCategory,
              caseReason: field.caseReason
            }
          default:
            return null
        }
      })
      .filter((x) => !!x)
  }

  const mapConditions = (fields, conditions) => {
    return conditions.map((x) => {
      const { fieldName, condition, value, negate, nextField } = x
      const field = fields.find((x) => x.fieldName === fieldName)
      if (typeof field === 'undefined') {
        return
      }

      return {
        fieldName: createCompositeName(field.fieldName, field.label, field.emailLabel),
        condition,
        value,
        negate,
        field: nextField && mapField(nextField.id)
      }
    })
  }

  const mapField = (id) => {
    const field = allDatoCmsContactSupportField.edges.find((x) => x.node.id === id)

    return {
      fields: mapFields(field.node.fields),
      conditions: mapConditions(field.node.fields, field.node.conditions)
    }
  }

  return (
    <TailwindLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt} consumer={consumer}>
      <HelmetDatoCms
        seo={datoCmsContactSupport.seoMetaTags || datoCmsSite.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <LocaleProvider>
        <FormWrapper
          datoCmsContactSupport={datoCmsContactSupport}
          locale={locale}
          mapField={mapField}
        />
      </LocaleProvider>
    </TailwindLayout>
  )
}

export default ContactSupportTemplate

export const query = graphql`
  fragment ContactSupportSelectProps on DatoCmsContactSupportFormSelect {
    fieldName
    label
    emailLabel
    placeholder
    options {
      text
      value
    }
    required
    requiredMessage
  }

  fragment ContactSupportOptionsProps on DatoCmsContactSupportFormOptionGroup {
    fieldName
    label
    emailLabel
    options {
      text
      value
    }
    required
    requiredMessage
  }

  fragment ContactSupportDecisionProps on DatoCmsContactSupportFormDecision {
    fieldName
    label
    emailLabel
    options {
      type: __typename
      ... on DatoCmsContactSupportFormOption {
        text
        value
      }
      ... on DatoCmsCta {
        text
        href
        title
        openTab
      }
    }
    required
    requiredMessage
  }

  fragment ContactSupportFeaturedArticleProps on DatoCmsContactSupportFormFeaturedArticle {
    fieldName
    label
    emailLabel
    options {
      text
      value
    }
    initiallyCollapsed
    article {
      id: originalId
      title
      subtitle
      content
      slug
      supportProducts {
        id: originalId
      }
    }
    required
  }

  fragment ContactSupportFormTextContentProps on DatoCmsContactSupportFormTextContent {
    contentNode {
      childMarkdownRemark {
        html
      }
    }
  }

  fragment ContactSupportFormTextFieldProps on DatoCmsContactSupportFormTextField {
    fieldName
    label
    emailLabel
    required
    requiredMessage
    pattern
    patternMessage
    maxLength
  }

  fragment ContactSupportFormTextAreaProps on DatoCmsContactSupportFormTextArea {
    fieldName
    label
    emailLabel
    required
    requiredMessage
    maxLength
  }

  fragment ContactSupportFormDateFieldProps on DatoCmsContactSupportFormDateField {
    fieldName
    label
    emailLabel
    required
    requiredMessage
  }

  fragment ContactSupportFormFieldTimeFieldProps on DatoCmsContactSupportFormTimeField {
    fieldName
    label
    emailLabel
    required
    requiredMessage
  }

  fragment ContactSupportFormSubmitButtonProps on DatoCmsContactSupportFormSubmitButton {
    buttonText
    thankYouMessageNode {
      childMarkdownRemark {
        html
      }
    }
    enableRecaptcha
    recaptchaRequiredMessage
  }

  fragment ContactSupportFormEidFieldProps on DatoCmsContactSupportFormEidField {
    fieldName
    label
    emailLabel
    required
    requiredMessage
    patternMessage
  }

  fragment ContactSupportFormCountrySelectProps on DatoCmsContactSupportFormCountrySelect {
    fieldName
    label
    emailLabel
    language
    required
    requiredMessage
    multiselect
    populateIpLocation
  }

  fragment ContactSupportFormSliderProps on DatoCmsContactSupportFormSlider {
    fieldName
    label
    emailLabel
    required
    options {
      text
      value
    }
  }

  fragment ContactSupportFormOutsideLinkProps on DatoCmsContactSupportFormOutsideLink {
    title
    subtitle
    url
  }

  query ($locale: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsContactSupport(locale: { eq: $locale }) {
      seoMetaTags {
        tags
      }
      hero {
        type: __typename
        ...HeroProps
      }
      supportEmails {
        title
        text: subtitle
        email
      }
      tabs {
        title
        categoryField {
          id: originalId
        }
      }
      loadingText
    }
    allDatoCmsContactSupportField(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          id: originalId
          locale
          fields: formFields {
            type: __typename
            ... on DatoCmsContactSupportFormSelect {
              ...ContactSupportSelectProps
            }
            ... on DatoCmsContactSupportFormOptionGroup {
              ...ContactSupportOptionsProps
            }
            ... on DatoCmsContactSupportFormDecision {
              ...ContactSupportDecisionProps
            }
            ... on DatoCmsContactSupportFormFeaturedArticle {
              ...ContactSupportFeaturedArticleProps
            }
            ... on DatoCmsContactSupportFormTextContent {
              ...ContactSupportFormTextContentProps
            }
            ... on DatoCmsContactSupportFormTextField {
              ...ContactSupportFormTextFieldProps
            }
            ... on DatoCmsContactSupportFormTextArea {
              ...ContactSupportFormTextAreaProps
            }
            ... on DatoCmsContactSupportFormDateField {
              ...ContactSupportFormDateFieldProps
            }
            ... on DatoCmsContactSupportFormSubmitButton {
              ...ContactSupportFormSubmitButtonProps
            }
            ... on DatoCmsContactSupportFormEidField {
              ...ContactSupportFormEidFieldProps
            }
            ... on DatoCmsContactSupportFormCountrySelect {
              ...ContactSupportFormCountrySelectProps
            }
            ... on DatoCmsContactSupportFormSlider {
              ...ContactSupportFormSliderProps
            }
            ... on DatoCmsContactSupportFormOutsideLink {
              ...ContactSupportFormOutsideLinkProps
            }
            ... on DatoCmsContactSupportFormTimeField {
              ...ContactSupportFormFieldTimeFieldProps
            }
            ... on DatoCmsCaseManagementCustomerFaultCase {
              caseProduct
              caseType
              caseCategory
              caseReason
            }
            ... on DatoCmsCaseManagementProvisionCase {
              caseProduct
              caseType
              caseCategory
              caseReason
              caseServiceLoss
            }
            ... on DatoCmsCaseManagementGeneralInquiryCase {
              caseProduct
              caseType
              caseCategory
            }
          }
          conditions {
            fieldName
            condition
            negate
            value
            nextField {
              id: originalId
            }
          }
        }
      }
    }
    allDatoCmsSupportIndex {
      edges {
        node {
          url
          supportProducts {
            id: originalId
          }
        }
      }
    }
  }
`
